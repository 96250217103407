<!--A SIGLA "KB" significa Kanban-->
<!--A SIGLA "CP" significa Conversa padrão-->
<template>
    <b-modal ref="modalCriarFormulario"
             modal-class="modalCriarFormulario"
             dialog-class="modalCriarFormulario"
             content-class="contentModalCriarFormulario"
             v-model="exibe"
             centered
             scrollable
             :size="respostaAutomaticaAtiva ? 'xl' : 'md'"
             body-class="app-modal-custom-header"
             header-class="app-modal-custom-header"
             footer-class="app-modal-custom-footer"
             title="Novo formulário"
             hide-footer
             hide-header-close
             no-close-on-backdrop>
        <b-row>
            <b-col id="containerInicial">
                <label class="labelFormulario" for="inputFormularioTitulo">Título</label>
                <input v-model="formularioTitulo" id="inputFormularioTitulo" class="inputFormulario" type="text" />
                <label class="labelFormulario" for="textareaFormulario" style="margin-top:15px;">Descrição</label>
                <textarea rows="10" max-rows="10"
                          v-model="formularioDescricao"
                          id="textareaFormulario">
                </textarea>
                <!--Sliders ativações >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>-->

                <b-row class="border mx-0 mt-3 py-2">
                    <b-col>
                        <div id="containerBotao">
                            <label class="switch">
                                <input type="checkbox" v-model="respostaAutomaticaAtiva" />
                                <span class="slider"></span>
                            </label>
                            <span id="labelBotao">{{respostaAutomaticaAtiva ? 'Desativar reposta automática' : 'Ativar reposta automática'}}</span>
                        </div>
                    </b-col>
                </b-row>

                <b-row class="border mx-0 mt-3 py-2">
                    <b-col>
                        <div id="containerBotao">
                            <label class="switch">
                                <input type="checkbox" v-model="respostaAutomaticaAtivaWhats" />
                                <span class="slider"></span>
                            </label>
                            <span id="labelBotao">{{respostaAutomaticaAtivaWhats ? 'Desativar reposta automática WhatsApp' : 'Ativar reposta automática WhatsApp'}}</span>
                        </div>
                    </b-col>
                </b-row>
                <b-row class="border mx-0 mt-3 py-2">
                    <b-col>
                        <div id="containerBotao">
                            <label class="switch">
                                <input type="checkbox" v-model="validarCodigoAtivo" />
                                <span class="slider"></span>
                            </label>
                            <span id="labelBotao">{{validarCodigoAtivo ? 'Desativar codigo validação' : 'Ativar codigo validação'}}</span>
                        </div>
                        <div v-if="validarCodigoAtivo">
                            <div class="containerSelect">
                                <select v-model="respostacampoParaValidacao">
                                    <option disabled :selected="respostacampoParaValidacao === null" value="null">Selecione um campo do formulario para contabilizar</option>
                                    <option v-for="campo in camposParaValidacao" :value="campo.Id">{{campo.NomeCampo}}</option>
                                </select>
                            </div>
                            <label for="valorLimite" class="labelFormulario">Valor limite</label>
                            <input id="valorLimite" class="inputFormulario" type="text" v-model="valorLimite" />
                            <label for="fraseLimite" class="labelFormulario">Frase para limite atingido</label>
                            <textarea rows="3" style="height:auto" id="fraseLimite" class="inputFormulario" type="text" v-model="fraseLimite" />

                        </div>
                    </b-col>
                </b-row>

            </b-col>
            <b-col cols="8" v-if="respostaAutomaticaAtiva">
                <h6>Resposta automática</h6>
                <div class="styles-inputs">
                    <label class="labelFormulario" for="inputRespostaTitulo">Assunto</label>
                    <input v-model="respostaAutomaticaAssunto" id="inputRespostaTitulo" class="inputFormulario" type="text" />
                </div>
                <div class="editor-container p-0">
                    <label class="labelFormulario" for="editor">Editor</label>
                    <div class="containerCK">
                        <CKEditor ref="editor" :content.sync="mensagemEncaminhamento" />
                    </div>
                </div>
                <b-row>
                    <b-col>
                        <div class="containerSelect" v-if="formularioDados && formularioDados.Campos.some(el => el.FormularioCampoTipo == 0)">
                            <select v-model="respostaAutomaticaCampo">
                                <option disabled :selected="respostaAutomaticaCampo === null" value="null">Selecione um campo que represente o email</option>
                                <option v-if="campo.FormularioCampoTipo == 0" v-for="campo in formularioDados.Campos" :value="campo.Id" :selected="respostaAutomaticaCampo === campo.Id">{{campo.NomeCampo}}</option>
                            </select>
                        </div>
                        <div class="containerSelect" v-else-if="formularioDados != null && !formularioDados.Campos.some(el => el.FormularioCampoTipo == 0)">
                            <input placeholder="Não há campo de texto que represente o email" class="inputFormulario" type="text" disabled />
                        </div>
                        <div class="containerSelect" v-else>
                            <input placeholder="Necessário cadastrar um campo de texto" class="inputFormulario" type="text" disabled />
                        </div>
                    </b-col>
                    <b-col>
                        <div class="containerSelect">
                            <select v-model="respostaAutomaticaSetor">
                                <option disabled :selected="respostaAutomaticaSetor === null" value="null">Selecione o setor</option>
                                <option v-for="setor in setores" :value="setor.Id" :selected="respostaAutomaticaSetor === setor.Id">{{ setor.Nome }}</option>
                            </select>
                        </div>
                    </b-col>
                </b-row>
                <b-row>

                    <b-col>
                        <div class="containerSelect">
                            <span style="font-size:10px; font-family: Verdana">
                                <i>
                                    * Para usar a validação de codigo:<br />
                                    primeiro deve-se <b>ativar codigo validação</b> após você poderá escolher ou nao um campo e uma quantidade para validar este fomulario e no corpo de email da resposta deve conter o termo <b>{CODE}</b> onde o mesmo será substituido por um código aleatorio a ser validado posteriormente.<br /><br />

                                    * Para usar como cadastro unico por CPF:<br />
                                    deve-se <b>ativar cadastro unico</b> após você poderá escolher que será preenchido o CPF, assim só sera permitido um cadastro por CPF.<br /><br />

                                    * Para usar como cadastro de cliente:<br />
                                    deve se <b>ativar cadastro cliente</b> após você deverá escolher cada campo quer representa o cadastro de cliente que será preenchido
                                </i>
                            </span>
                        </div>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <div id="holdBtns">
                    <div id="btnCancelarCriarformulario" class="btnModalCriarFormulario" @click="hide()">Cancelar</div>
                    <div id="btnSalvarCriarformulario" class="btnModalCriarFormulario" @click="formularioCriarEditar()">Confirmar</div>
                </div>

            </b-col>
        </b-row>
        <b-overlay :show="loading" rounded="sm" no-wrap>
        </b-overlay>
    </b-modal>
</template>
<script>
    import axios from 'axios'
    import CKEditor from "@/components/editors/CKEditor";
    export default {
        name: 'ModalCriarFormulario',
        components: {
            CKEditor
        },
        props: {
            formularioDados: {
                NomeFormulario: "",
                Descricao: "",
                DataCadastro: "",
                RespostaAutomatica: false,
                validarCodigoAtivo: false,
                
                
                fraseLimite: "",
                Campos: [],
                Id: null
            }
        },
        data() {
            return {
                loading: false,
                exibe: false,
                formularioTitulo: "",
                formularioDescricao: "",
                formularioId: null,
                respostaAutomaticaAssunto: '',
                respostaAutomaticaSetor: null,
                respostaAutomaticaCampo: null,
                respostaAutomaticaAtiva: false,
                respostaAutomaticaAtivaWhats: false,
                validarCodigoAtivo: false,
                
               
                editorToolsSet: "B|I|U|S|A|IMG",
                editor: {},
                respostaAutomaticaId: null,
                mensagemEncaminhamento: '',
                setores: [],
                camposParaValidacao: [],
                respostacampoParaValidacao: null,
                respostaCampoCadastroUnico: null,
                valorLimite: 0,
                configuracoes: [],
                resultLimite: null,
                fraseLimite: ""

            }
        },
        methods: {
            show() {
                this.exibe = true;
                this.$nextTick(() => {
                    if (this.formularioDados != null) {
                        this.formularioTitulo = this.formularioDados?.NomeFormulario;
                        this.formularioDescricao = this.formularioDados?.Descricao;
                        this.respostaAutomaticaAtiva = this.formularioDados?.RespostaAutomatica;
                        this.respostaAutomaticaAtivaWhats = this.formularioDados?.RespostaAutomaticaWhatsApp;
                       
                        
                        this.validarCodigoAtivo = this.formularioDados?.HabilitarValidacaoCodigo;
                        this.fraseLimite = this.formularioDados?.FraseLimiteFormulario;
                        this.formularioId = this.formularioDados?.Id;
                        if (this.formularioDados.RespostaAutomaticaDTO && this.formularioDados.RespostaAutomaticaDTO != {}) {
                            this.respostaAutomaticaAssunto = this.formularioDados.RespostaAutomaticaDTO.Assunto ?? '';
                            this.respostaAutomaticaSetor = this.formularioDados.RespostaAutomaticaDTO.SetorId ?? null;
                            this.respostaAutomaticaCampo = this.formularioDados.RespostaAutomaticaDTO.FormularioCampoId ?? null;
                            this.mensagemEncaminhamento = this.formularioDados.RespostaAutomaticaDTO.Mensagem ?? '';
                            this.respostaAutomaticaId = this.formularioDados.RespostaAutomaticaDTO.Id ?? null;
                        }
                    }
                    this.buscaConfiguracoes();
                });
                this.buscaSetores();
                this.camposValidacao();




            },
            hide() {
                this.exibe = false
                this.limparInputs();
            },
            limparInputs() {
                this.formularioTitulo = "";
                this.formularioDescricao = "";
                this.formularioId = null;
                this.respostaAutomaticaAssunto = '';
                this.respostaAutomaticaSetor = null;
                this.respostaAutomaticaCampo = null;
                this.mensagemEncaminhamento = '';
                this.respostaAutomaticaAtiva = false;
                this.respostaAutomaticaAtivaWhats = false;
                
                
                this.validarCodigoAtivo = false;
                this.respostaAutomaticaId = null;
                this.camposParaValidacao = [],
                    this.respostacampoParaValidacao = null,
                    this.respostaCampoCadastroUnico = null,
                    this.valorLimite = 0
                this.fraseLimite = ""

            },
            formularioCriarEditar() {
                $("#caixa_carregamento").show();

                let respostaAutomatica = null;

                respostaAutomatica = {
                    "Id": this.respostaAutomaticaId,
                    "Assunto": this.respostaAutomaticaAssunto,
                    "Mensagem": this.mensagemEncaminhamento,
                    "FormularioCampoId": this.respostaAutomaticaCampo ?? null,
                    "SetorId": this.respostaAutomaticaSetor ?? null,
                    "FormularioId": this.formularioId ?? null,
                }
                let data = {
                    "NomeFormulario": this.formularioTitulo,
                    "Descricao": this.formularioDescricao,
                    "Id": this.formularioId,
                    "RespostaAutomatica": this.respostaAutomaticaAtiva,
                    "RespostaAutomaticaWhatsApp": this.respostaAutomaticaAtivaWhats,
                    
                    
                    "HabilitarValidacaoCodigo": this.validarCodigoAtivo,
                    "ValorLimiteFormulario": this.valorLimite,
                    "FraseLimiteFormulario": this.fraseLimite,
                    "RespostacampoParaValidaLimite": this.respostacampoParaValidacao,
                    "RespostaCampoUnico": this.respostaCampoCadastroUnico,
                    "RespostaAutomaticaDTO": respostaAutomatica
                };
                if (this.formularioDados == null) { this.formularioCriar(data) } else { this.formularioEditar(data) }
            },
            formularioCriar(dados) {
                axios.post('/api/formularios/CriarFomulario', dados)
                    .then((response) => {
                        this.hide();
                        this.$emit('carregaNovoFormulario', response.data)
                        $("#caixa_carregamento").hide();
                    }, (error) => {
                        console.error(error);
                        $("#caixa_carregamento").hide()
                    });
            },
            formularioEditar(dados) {
                axios.put('/api/formularios/EditarFomulario', dados)
                    .then((response) => {
                        this.hide();
                        this.$emit('carregaEdicaoFormulario', response.data)
                        $("#caixa_carregamento").hide();
                    }, (error) => {
                        console.error(error);
                        $("#caixa_carregamento").hide()
                    });
            },
            buscaSetores() {
                axios.get('api/Setor/lista-basica').then(res => {
                    this.setores = res.data;
                })
            },
            camposValidacao() {
                this.$nextTick(() => {
                    this.camposParaValidacao = [];
                    for (var item in this.formularioDados.Campos) {
                        if (this.formularioDados.Campos[item].FormularioCampoTipo == 0 || this.formularioDados.Campos[item].FormularioCampoTipo == 3) {
                            this.camposParaValidacao.push(this.formularioDados.Campos[item]);
                        }

                    }
                });
            },
            buscaConfiguracoes() {
                axios.get('api/formularios/BuscaConfigFormulario/' + this.formularioDados.Id).then(res => {
                    this.configuracoes = res.data.Configuracoes;
                    this.resultLimite = res.data.Configuracoes.find(f => f.Acao == 3);
                    this.resultunico = res.data.Configuracoes.find(f => f.Acao == 1);
                    this.respostaCampoCadastroUnico = this.resultunico.CampoId;
                    this.respostacampoParaValidacao = this.resultLimite.CampoId;
                    this.valorLimite = this.resultLimite.Limite;
                })
            }
        },

        created() {

        }
    }
</script>

<style scoped>
    #textareaFormulario {
        resize: none;
        min-height: 3vh;
        background-color: var(--cinza-1);
        color: var(--cinza-5);
        width: 100%;
        border: 1px solid var(--cinza-4);
        outline: none;
        font-size: 15px;
        margin-bottom: 2vh;
    }

    .containerSelect {
        margin-top: 2vh;
    }

    #containerInicial {
        margin-top: 26px;
    }

    .info-email-field {
        display: flex;
        background-color: var(--cinza-3);
        margin-bottom: 5px;
        align-items: center;
        padding: 0;
    }

        .info-email-field label {
            margin: 0 7px;
            color: #666;
        }

        .info-email-field > input[type=text] {
            width: 100%;
            background-color: transparent;
            border: 0;
            padding: 0;
            margin: 7px 7px 7px 0;
            font-size: 13px;
        }

            .info-email-field > input[type=text]:focus {
                border: 0;
                box-shadow: none;
            }

        .info-email-field > select {
            width: 100%;
            background-color: transparent;
            border: 0;
            padding: 0;
            font-size: 13px;
            border: none;
        }

    #containerBotao {
        display: flex;
        align-content: center;
        justify-content: flex-start;
        flex-direction: row;
        align-items: flex-start;
        flex-wrap: wrap;
    }

    #labelBotao {
        font-size: 14px;
        color: var(--cinza-5);
        margin-bottom: 0px;
    }

    .switch {
        position: relative;
        display: inline-block;
        width: 46px;
        height: 20px;
        margin-right: 0.3vw;
    }

        .switch input {
            opacity: 0;
            width: 0;
            height: 0;
        }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: var(--cinza-5);
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 50px;
    }

    .styles-inputs input, select {
        background-color: var(--cinza-1) !important;
        font-size: 15px;
        padding: 5px 7px;
        outline-style: none;
        width: 100%;
        margin-bottom: calc(2vh + 12px);
    }

    .styles-inputs textarea {
        resize: none;
        min-height: 3vh;
        background-color: var(--cinza-1);
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 16px;
        width: 16px;
        left: 4px;
        bottom: 2px;
        background-color: #fff;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 50%;
    }

    input:checked + .slider {
        background-color: #1CDF5D;
    }

        input:checked + .slider:before {
            transform: translateX(24px);
        }

    .inputFormulario {
        background-color: var(--cinza-1);
        color: var(--cinza-5);
        width: 100%;
        border: 1px solid var(--cinza-4);
        outline: none;
        height: 35px;
        font-size: 15px;
        margin-bottom: 2vh;
    }

        .inputFormulario:hover, .inputFormulario:focus {
            outline: none !important;
        }

    .labelFormulario {
        font-size: 14px;
        color: #666;
        margin-bottom: 0px;
    }

    #holdBtns {
        display: block;
        float: right;
        margin: 20px 0px;
    }

    .btnModalCriarFormulario {
        display: inline-block;
        padding: 5px 15px;
        cursor: pointer;
    }

        .btnModalCriarFormulario:hover {
            opacity: 0.8;
        }

    #btnCancelarCriarformulario {
        border-bottom: 2px solid #808080;
        margin-right: 15px;
    }

    #btnSalvarCriarformulario {
        border-bottom: 2px solid transparent;
        background-color: #3adf5b;
        color: #FFF;
    }
</style>

<style>
    .containerCK .ck-content {
        height: 400px;
        background-color: var(--cinza-1) !important;
        max-height: 400px;
    }
</style>