<!--A SIGLA "KB" significa Kanban-->
<!--A SIGLA "CP" significa Conversa padrão-->
<template>
    <b-modal ref="modalCriarFormulario"
             modal-class="modalCriarFormulario"
             dialog-class="modalCriarFormulario"
             content-class="contentModalCriarFormulario"
             v-model="exibe"
             body-class="app-modal-custom-header"
             header-class="app-modal-custom-header"
             footer-class="app-modal-custom-footer"
             title="Novo campo"
             hide-footer>
        <b-row>
            <b-col>
                <label class="labelFormulario" for="inputCampoOrdem">Ordem</label>
                <input v-model="ordemCampo" id="inputCampoOrdem" class="inputFormulario" type="number" />
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <label class="labelFormulario" for="inputNomeCampo">Nome Campo</label>
                <input v-model="NomeCampo" id="inputNomeCampo" class="inputFormulario" type="text" />
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <label class="labelFormulario" for="inputTipoCampo">Tipo do campo</label>
                <select class="inputFormulario" v-model="tipoCampo">
                    <option value="-1" disabled>Selecione uma opção</option>
                    <option v-for="campo in tiposCampos" :value="campo.valor">{{campo.texto}}</option>
                </select>
            </b-col>
        </b-row>


        <b-row v-for="(item, index) in itens" v-if="tipoCampo==3 || tipoCampo==4 || tipoCampo==5 ">
            <b-col>
                <span v-if="index===0" style="font-size:10px; font-style:italic">Opções</span>
                <div class="holdInputDinamico" v-model="item.controle = index">
                    <input type="text" v-model="item.texto" class="inputFormularioOpcao" />&nbsp;

                    <label v-if=" tipoCampo==4 || tipoCampo==5" :for="`arquivo-formulario${index}`"><i class="far fa-image btnCarregaImagemFormulario"></i></label>
                    <input :id="`arquivo-formulario${index}`"
                           :ref="`arquivo-formulario${index}`"
                           style="position: absolute; width: 100%; height: 100%; top: 0; left: 0; opacity: 0; visibility: hidden;"
                           type="file"
                           @change="anexarArquivoOpcao(index)" />
                    <i class="far fa-trash-alt btnExcluiOpcoesFormulario" @click="removerItem(item)"></i>


                </div>
                <div v-if="verificarUrl(item?.image?.nome)" class="text-truncate" style="margin: -15px 0px 10px 0px">
                    <a :href="item?.image?.nome" target="_blank" style="font-size: 10px; font-style: italic; position: relative; top: 0px;">{{item?.image?.nome}}</a>                <!--<i class="fas fa-times btnExcluiOpcoesImagem"></i>-->
                </div>
                <div v-else style="font-size: 10px; font-style: italic; position: relative; top: 0px;">{{item?.image?.nome}}</div>
            </b-col>
        </b-row>
        <b-row v-if="tipoCampo==3 || tipoCampo==4 || tipoCampo==5">
            <b-col>
                <div id="btnInserirElemento" @click="inserirOpcao">+ Nova opção</div>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <div style="margin: 10px 0px" v-if="tipoCampo==4 ">
                    <input v-model="mostrarCaixaTexto" id="inputCheckUltimaPergunta" type="checkbox" />
                    <span style="margin-left:10px">Mostrar campo de texto como ultima opção</span>
                </div>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <label class="labelFormulario" for="inputFormularioTitulo">Campo Obrigatório</label>
                <select class="inputFormulario" v-model="Obrigatorio">
                    <option value="-1" disabled>Selecione uma opção</option>
                    <option value="0">Não</option>
                    <option value="1">Sim</option>
                </select>
            </b-col>
        </b-row>
        <b-row v-if="tipoCampo == 0 && fomularioCadastroCliente">
            <b-col>
                <label class="labelFormulario" for="inputFormularioTitulo">Campo cadastro cliente</label>
                <select class="inputFormulario" v-model="campoParaCliente">
                    <option value="-1" disabled>Selecione uma opção</option>
                    <option :value="item.valor" v-for="(item, index) in cadastroCliente">{{item.texto}}</option>
                </select>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <div id="holdBtns">
                    <div id="btnCancelarCriarformularioCampos" class="btnModalCriarFormularioCampos" @click="hide()">Cancelar</div>
                    <div id="btnSalvarCriarformularioCampos" class="btnModalCriarFormularioCampos" @click="formularioCamposCriarEditar()">Confirmar</div>
                </div>
            </b-col>
        </b-row>
        <b-overlay :show="loading" rounded="sm" no-wrap>
        </b-overlay>

    </b-modal>
</template>

<script>
    import axios from 'axios'

    // Services
    export default {
        name: 'ModalCriarFormulario',

        props: {
            fomularioCadastroCliente: false,
            formularioId: null,
            formularioDados: { NomeFormulario: "", Descricao: "", DataCadastro: "", Id: null, Campos: [] }
        },
        data() {
            return {
                loading: false,
                exibe: false,
                ordemCampo: 0,
                NomeCampo: "",
                tipoCampo: 0,
                opcoesCampo: null,
                itens: [],
                mostrarCaixaTexto: false,
                Obrigatorio: 0,
                campoParaCliente: "",
                listaIds: [],
                cadastroCliente: [
                    { valor: "RazaoSocial", texto: "Nome ou Razão social" },
                    { valor: "CnpjCpf", texto: "CPF ou CNPJ" },
                    { valor: "Email", texto: "Email" },
                    { valor: "Telefone", texto: "Telefone" },
                ],
                tiposCampos: [
                    { valor: 0, texto: "Caixa de Texto" },
                    { valor: 1, texto: "Numérico" },
                    { valor: 3, texto: "Lista Suspensa" },
                    { valor: 4, texto: "Caixa de Seleção" },
                    { valor: 5, texto: "Multipla escolha" },
                ]
            }
        },
        watch: {
            itens() {
                return this.itens;
            }
        },
        methods: {
            show() {

                this.exibe = true;
                this.$nextTick(() => {

                    if (this.formularioDados == null) { this.limparInputs(); return }
                    let campo = this.formularioDados;
                    console.log(campo.CamposOpcoes)
                    this.ordemCampo = campo.Ordem;
                    this.NomeCampo = campo.NomeCampo;
                    this.campoParaCliente = campo.CampoCadastro;
                    this.tipoCampo = campo.FormularioCampoTipo;
                    this.itens = campo.CamposOpcoes ? campo.CamposOpcoes?.map(m => ({Id: m.Id, texto: m.NomeOpcao, image: { nome: m.Arquivo }, imagemNova: false, urlImagem: m.Arquivo })) : [];
                    this.mostrarCaixaTexto = campo.Especificar;
                    this.Obrigatorio = campo.Obrigatorio ? 1 : 0;
                    this.formularioId = campo.FormularioId;
                });
            },
            verificarUrl(url) {
                var urlPattern = new RegExp('^(https?:\\/\\/)?' + // validate protocol
                    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
                    '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
                    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
                    '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
                    '(\\#[-a-z\\d_]*)?$', 'i'); // validate fragment locator
                return !!urlPattern.test(url);
            },
            limparInputs() {
                this.ordemCampo = 0;
                this.NomeCampo = "";
                this.tipoCampo = 0;
                this.campoParaCliente = "";
                this.opcoesCampo = null;
                this.mostrarCaixaTexto = false;
                this.listaIds = [],
                this.Obrigatorio = 0;
                this.itens = [{ texto: "", image: null, controle: "" }];
            },
            hide() {
                this.exibe = false;
                this.limparInputs();
            },
            async formularioCamposCriarEditar() {
                let form = new FormData();
                form.append("Ordem", this.ordemCampo);
                form.append("FormularioId", this.formularioId);
                form.append("NomeCampo", this.NomeCampo);
                form.append("CampoCadastro", this.campoParaCliente);
                form.append("FormularioCampoTipo", this.tipoCampo);
                form.append("Obrigatorio", this.Obrigatorio == 1 ? true : false);
                form.append("Especificar", this.mostrarCaixaTexto);

                if (this.formularioDados != null) { form.append("Id", this.formularioDados == null ? null : this.formularioDados?.Id) };
                let opcoesCampo = [];

                for (var i in this.itens) {
                    if (!this.itens[i].image) {
                        opcoesCampo.push({Id: this.itens[i].Id, Arquivo: new Blob(), Texto: this.itens[i].texto, Url: this.itens[i].urlImagem })
                        //form.append("Arquivos", new Blob(), null);
                        //form.append("Textos", this.itens[i].texto);
                        continue;
                    }
                    if (!this.itens[i].imagemNova) {
                        opcoesCampo.push({Id: this.itens[i].Id, Arquivo: new Blob(), Texto: this.itens[i].texto, Url: this.itens[i].urlImagem })
                        //form.append("Arquivos", new Blob(), null);
                        //form.append("Textos", this.itens[i].texto);
                        continue;
                    }

                    let content = await fetch(this.itens[i].image.url).then(content => content.blob()).then(blob => blob);
                    opcoesCampo.push({Id: this.itens[i].Id, Arquivo: content, Texto: this.itens[i].texto, nomeArquivo: this.itens[i].image.nome, Url: this.itens[i].urlImagem })
                    //form.append("Arquivos", content, this.itens[i].image.nome);
                    //form.append("Textos", this.itens[i].texto);
                }

                let dados = {
                    "Ordem" : this.ordemCampo,
                    "FormularioId" : this.formularioId,
                    "NomeCampo" : this.NomeCampo,
                    "CampoCadatro" : this.campoParaCliente,
                    "FormularioCampoTipo" : this.tipoCampo,
                    "Obrigatorio" : this.Obrigatorio == 1 ? true : false,
                    "Especificar" : this.mostrarCaixaTexto,
                    "Opcoes" : opcoesCampo
                };
                opcoesCampo.forEach((opcao, index) => {
                    form.append(`ArquivosTextos[${index}].Arquivo`, opcao.Arquivo, opcao.nomeArquivo);
                    form.append(`ArquivosTextos[${index}].Texto`, opcao.Texto);
                    if(opcao.Id) form.append(`ArquivosTextos[${index}].Id`, opcao.Id);
                    if (opcao.Url) form.append(`ArquivosTextos[${index}].Url`, opcao.Url);
                });
                if(this.listaIds.length > 0){
                    let ids = Object.assign({}, ...this.listaIds.map((item, index) => ({ [`ids[${index}]`]: item })));
                    axios.delete('/api/formularios/ExcluirOpcoes',{
                        params: ids
                    }).catch(error => {
                        console.error(error);
                    })
                }
                if (this.formularioDados == null) { this.formularioCamposCriar(form) } else { this.formularioCamposEditar(form) }

            },
            formularioCamposCriar(data) {
                this.hide();
                $("#caixa_carregamento").show()
                axios.post('/api/formularios/CriarFomularioCampos', data, { headers: { 'Content-Type': 'multipart/form-data' } })
                    .then((response) => {
                        this.hide();
                        this.$emit('carregaNovoCampoFormulario', response.data)
                        $("#caixa_carregamento").hide();
                    }, (error) => {
                        console.log(error);
                        $("#caixa_carregamento").hide()
                    });
            },
            formularioCamposEditar(data) {
                this.hide();
                $("#caixa_carregamento").show()
                axios.put('/api/formularios/EditarFomularioCampos', data, { headers: { 'Content-Type': 'multipart/form-data' } })
                    .then((response) => {
                        this.hide();
                        this.$emit('carregaEdicaoCampoFormulario', response.data)
                        $("#caixa_carregamento").hide();
                    }, (error) => {
                        console.log(error);
                        $("#caixa_carregamento").hide()
                    });
            },

            inserirOpcao() {
                this.itens.push({ texto: "", image: null, controle: "" })
            },
            removerItem(dados) {
                console.log(dados.Id)
                if(dados.Id){
                    this.listaIds.push(dados.Id)
                    console.log(this.listaIds)
                }
                let indexExclusao = this.itens.findIndex(f => f.controle == dados.controle);
                this.itens.splice(indexExclusao, 1);
            },
            anexarArquivoOpcao(index) {
                let file = this.$refs["arquivo-formulario" + index][0].files[0];

                let reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    let arquivo = {
                        nome: file.name,
                        tipo: file.type,
                        url: reader.result,
                        status: "novo"
                    };
                    this.itens[index].image = arquivo;
                    this.itens[index].imagemNova = true;

                    console.log("Verificando ", this.itens)
                }



            },




        },


    }
</script>

<style scoped>

    .inputFormulario {
        background-color: var(--cinza-2);
        color: var(--cinza-5);
        width: 100%;
        border: 1px solid var(--cinza-4);
        outline: none;
        height: 35px;
    }

        .inputFormulario:hover, .inputFormulario:focus {
            outline: none !important;
        }

    .inputFormularioOpcao {
        background-color: var(--cinza-2);
        color: var(--cinza-5);
        width: calc(100vw - 30px);
        border: 1px solid var(--cinza-4);
        outline: none;
        height: 35px;
    }

        .inputFormularioOpcao:hover, .inputFormularioOpcao:focus {
            outline: none !important;
        }

    .labelFormulario {
        margin-top: 15px;
        font-size: 14px;
        color: #666;
        margin-bottom: 0px;
    }

    #holdBtns {
        display: block;
        float: right;
        margin: 20px 0px;
    }

    .btnModalCriarFormularioCampos {
        display: inline-block;
        padding: 5px 15px;
        cursor: pointer;
    }

        .btnModalCriarFormularioCampos:hover {
            opacity: 0.8;
        }

    #btnCancelarCriarformularioCampos {
        border-bottom: 2px solid #808080;
        margin-right: 15px;
    }

    #btnSalvarCriarformularioCampos {
        border-bottom: 2px solid transparent;
        background-color: #3adf5b;
        color: #FFF;
    }

    .btnCarregaImagemFormulario {
        font-size: 20px;
        cursor: pointer;
        margin: 7px 6px;
        top: 15px;
    }

    .btnExcluiOpcoesFormulario {
        font-size: 18px;
        cursor: pointer;
        margin: 7px 0px;
    }

    .holdInputDinamico {
        margin: 5px 0px;
        display: inline-flex;
        width: 100%;
    }

    #btnInserirElemento {
        cursor: pointer;
        color: #666;
        border: 1px solid #666;
        display: inline-block;
        padding: 5px 15px;
        cursor: pointer;
        width: 100%;
        text-align: center;
    }

    .btnExcluiOpcoesImagem {
        color: red;
    }
</style>