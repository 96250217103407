<template>
    <b-modal ref="modalInformacaoFormulario"
             modal-class="modalInformacaoFormulario"
             dialog-class="modalInfomacoesFormulario"
             content-class="contentModalInfomacoes"
             v-model="exibe"
             body-class="app-modal-custom-header"
             header-class="app-modal-custom-header"
             footer-class="app-modal-custom-footer"
             title=""
             hide-footer>
        <div style="color: #666; font-weight: bold; font-size: 20px ">Visualização formulário #{{informacaoCarregada[0].Codigo.split("<>")[0]}}</div>
        <div style="color: #666; font-size:12px">Enviado em {{informacaoCarregada[0]?.Data}}</div><br>
        <div v-for="item in informacaoCarregada">
            <span style="color: #666;font-weight:bold">{{item?.NomeCampo}}</span>
            <b-container class="bv-example-row">
                
                <b-row style="display:flex">
                    <div v-if="item.Multiplo" v-for="(opcao, i ) in item.Codigo.split('<>')">
                        <b-col style="border:solid 1px #666">
                            <!--<div v-if="opcao.RespostaImagem" class="imagensFormulario" :style="{'background-image':`url(${opcao.RespostaImagem})`}"></div>-->
                            <span style="font-size:10px">Resposta Formulario : <b>#{{item.Codigo.split('<>')[i]}}</b>,  {{item.Data.split('<>')[i]}}</span><br />
                            {{item.Resposta.split('<>')[i]}}
                        </b-col>
                    </div>
                    <div v-else>
                        <b-col>
                            <div v-if="item.RespostaImagem" class="imagensFormulario" :style="{'background-image':`url(${item.RespostaImagem})`}"></div>
                            {{item.Resposta}}
                        </b-col>
                    </div>
                    

                </b-row>
                
                
            </b-container>
            <b-container class="bv-example-row">





                <!--<b-row v-for="(opcoes, i) in separar(item)">
                    <b-col v-for="(opcao, index) in opcoes">
                        <div v-if="opcao.RespostaImagem" class="imagensFormulario" :style="{'background-image':`url(${opcao.RespostaImagem})`}"></div>
                        {{opcao}}
                    </b-col>-->

                </b-row>
            </b-container>
            <!--<div v-for="i in item">
                <span style="color: #666;">&nbsp;{{i.Resposta}}</span>
            </div>-->
            <br>
        </div>
        <div id="holdBtns">
            <div id="btnCancelarInfomacao" class="btnModalCriarFormulario" @click="hide()">Fechar</div>
        </div>
    </b-modal>

</template>
<script>
    import axios from 'axios'
    export default {
        name: 'ModalInformacaoFormulario',

        props: {
            dadosInformacao: null
        },
        data() {
            return {
                loading: false,
                exibe: false,
                informacaoCarregada: [[]]
            }
        },
        methods: {
            show() {
                this.exibe = true;
                this.$nextTick(() => {
                    this.carregaInformacao();
                });

            },
            hide() {
                this.exibe = false

            },
            carregaInformacao() {

                axios.get('/api/formularios/BuscarFomularioPesquisa/' + this.dadosInformacao.controlePesquisa)
                    .then((response) => {
                        console.log("aqui", response.data)
                        this.informacaoCarregada = response.data;
                        $("#caixa_carregamento").hide();
                    }, (error) => {
                        console.log(error);
                        $("#caixa_carregamento").hide()
                    });

            },
            separar(dados) {
                this.$nextTick(() => {
                    let result = [];
                    let c = 0;
                    let valores = dados.split("<>")

                    for (var i = 0; i < valores; i++) {
                        result.push(dados[i]);
                    }
                    console.log("aqui dsdd", result)
                    return result;
                });

               
            }

        },
    }
</script>

<style scoped>

    .inputFormulario {
        background-color: var(--cinza-2);
        color: var(--cinza-5);
        width: 100%;
        border: 1px solid var(--cinza-4);
        outline: none;
        height: 28px;
    }

        .inputFormulario:hover, .inputFormulario:focus {
            outline: none !important;
        }

    .labelFormulario {
        margin: 0px;
        font-size: 14px;
        color: #666;
    }

    #holdBtns {
        display: block;
        float: right;
        margin: 20px 0px;
    }

    .btnModalCriarFormulario {
        display: inline-block;
        padding: 5px 15px;
        cursor: pointer;
    }

        .btnModalCriarFormulario:hover {
            opacity: 0.8;
        }

    #btnCancelarInfomacao {
        border-bottom: 2px solid #808080;
        margin-right: 15px;
    }

    #btnSalvarCriarformulario {
        border-bottom: 2px solid transparent;
        background-color: #3adf5b;
        color: #FFF;
    }

    .imagensFormulario {
        width: 150px;
        max-width: 150px;
        height: 150px;
        border-radius: 12px;
        border: 1px solid #ccc;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }
</style>